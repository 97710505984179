<template>
  <FormModal
    title="Lead Generation Form"
    endpoint="forms/lead-gen"
    method="PUT"
    class="modal--lead-gen-form"
    :data="data"
    v-bind="$attrs"
    @success="onSuccess"
  >
    <div v-if="loading" class="space-x-3">
      <Icon icon="spinner-third" prefix="fas" spin />
      <span>Loading...</span>
    </div>
    <div v-else>
      <p>
        Design a lead generation form that can be embedded into any collection for use on your website and landing
        pages.
      </p>
      <Tabs class="mt-8">
        <Tab title="Form">
          <InputGroup label="Title" name="title" optional>
            <TextInput v-model="data.title" />
          </InputGroup>

          <MarkdownInput
            v-model="data.pre_text"
            label="Pre-Text"
            name="pre_text"
            :suggested-max-length="350"
            optional
          />

          <InputGroup label="Button Label" name="button_label" optional>
            <TextInput v-model="data.button_label" :placeholder="defaults.button_label" />
          </InputGroup>
        </Tab>

        <Tab title="Fields">
          <Table>
            <TableRow v-for="(label, key) in fields" :key="key">
              <TableColumn title="Field">{{ label }}</TableColumn>
              <TableColumn title="Display"><Checkbox v-model="data[key + '_enabled']" /></TableColumn>
              <TableColumn title="Required">
                <Checkbox v-show="data[key + '_enabled']" v-model="data[key + '_required']" />
                <span v-show="!data[key + '_enabled']" class="text-supplement">-</span>
              </TableColumn>
            </TableRow>
          </Table>

          <InputGroup
            v-show="data.first_name_enabled"
            label="First Name Label"
            name="first_name_label"
            class="mt-8"
            :required="data.first_name_required"
          >
            <TextInput v-model="data.first_name_label" :placeholder="defaults.first_name_label" />
          </InputGroup>

          <InputGroup
            v-show="data.last_name_enabled"
            label="Last Name Label"
            name="last_name_label"
            :required="data.last_name_required"
          >
            <TextInput v-model="data.last_name_label" :placeholder="defaults.last_name_label" />
          </InputGroup>

          <InputGroup
            v-show="data.email_enabled"
            label="Email Label"
            name="email_label"
            :required="data.email_required"
          >
            <TextInput v-model="data.email_label" :placeholder="defaults.email_label" />
          </InputGroup>

          <InputGroup
            v-show="data.phone_enabled"
            label="Phone Label"
            name="phone_label"
            :required="data.phone_required"
          >
            <TextInput v-model="data.phone_label" :placeholder="defaults.phone_label" />
          </InputGroup>

          <InputGroup
            v-show="data.country_enabled"
            label="Country Label"
            name="country_label"
            :required="data.country_required"
          >
            <TextInput v-model="data.country_label" :placeholder="defaults.country_label" />
          </InputGroup>
        </Tab>
        <Tab title="Submission">
          <InputGroup label="Title" name="submission_success_title" optional>
            <TextInput v-model="data.submission_success_title" :placeholder="defaults.submission_success_title" />
          </InputGroup>

          <MarkdownInput
            v-model="data.submission_success_message"
            label="Message"
            name="submission_success_message"
            :placeholder="defaults.submission_success_message"
            :suggested-max-length="350"
            optional
          />

          <InputGroup label="Notify via email" name="lead_generation_notifications">
            <div class="mt-2 inline-flex items-center">
              <ToggleSwitch v-model="data.lead_generation_notifications" class="rounded-full" />
              <span class="ml-4">Receive email notification on new submissions</span>
            </div>
          </InputGroup>
        </Tab>
      </Tabs>
    </div>
  </FormModal>
</template>

<script>
import { Tab, Tabs } from "@syntax51/app-kit"
import MarkdownInput from "@/components/Form/MarkdownInput.vue"

export default {
  components: {
    MarkdownInput,
    Tab,
    Tabs
  },
  data: () => ({
    loading: true,
    fields: {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone: "Phone",
      country: "Country"
    },
    data: {
      title: null,
      pre_text: null,
      first_name_enabled: null,
      first_name_label: null,
      last_name_enabled: null,
      last_name_label: null,
      email_enabled: null,
      email_label: null,
      phone_enabled: null,
      phone_label: null,
      country_enabled: null,
      country_label: null,
      button_label: null,
      submission_success_title: null,
      submission_success_message: null,
      lead_generation_notifications: false
    },
    defaults: {
      first_name_label: null,
      last_name_label: null,
      email_label: null,
      phone_label: null,
      country_label: null,
      button_label: null,
      submission_success_title: null,
      submission_success_message: null
    }
  }),
  async created() {
    const response = await this.$api.get("forms/lead-gen")
    this.defaults = response.data.data.defaults
    delete response.data.data.defaults
    this.data = response.data.data
    this.loading = false
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Lead generation form successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.modal--lead-gen-form {
  .table__heading {
    @apply border-t-0 border-l-0 border-r-0;
  }

  .table__heading,
  .table__column {
    &:first-child {
      @apply pl-0;
    }

    &:last-child {
      @apply pr-0;
    }
  }
}
</style>
