<template lang="pug">
  AppLayout.page-forms
    Band(centered, contained)
      DataTable(title="Leads", label="PREMIUM", endpoint="forms/submissions", :sort="{'created_at': 'desc'}")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editLeadGenerationForm()") Manage Form
          Button(as="a" :href="signedUrl", type="primary", prepend-icon="download") Download CSV
        template(v-slot:rows="rows")
          DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
            DataTableColumn(title="When", sortable="created_at") {{ row.human_created_at }}
            DataTableColumn(title="First Name", sortable="first_name") {{ row.first_name }}
            DataTableColumn(title="Last Name", sortable="last_name") {{ row.last_name }}
            DataTableColumn(title="Email", sortable="email") {{ row.email }}
            DataTableColumn(title="Phone", sortable="phone") {{ row.phone }}
            DataTableColumn(title="Country", sortable="country_id", :class="{'text-supplement': !row.country}") {{ row.country ? row.country.name : '-' }}
            DataTableColumn(title="Collection", sortable="collection_id")
              router-link(:to="{name: 'collection', params: { id: row.collection.id }}") {{ row.collection.name }}
</template>

<script>
import EditLeadGenFormModal from "@/modals/EditLeadGenFormModal.vue"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Forms",
    signedUrl: null
  }),
  async created() {
    const response = await this.$api.get("forms/lead-gen/signed-url")
    this.signedUrl = response.data.url
  },
  methods: {
    async editLeadGenerationForm() {
      await this.$modal.show(EditLeadGenFormModal)
    }
  }
}
</script>

<style>
.page-forms {
  .card-title-label {
    @apply bg-secondary;
  }
}
</style>
